.animation-container {
    position: relative;
    width: 100%;
    height: 64px; /* Высота контейнера анимации */
    overflow: hidden;
  }
  
  .text-animation {
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
  }
  