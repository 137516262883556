@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;500;700&family=Roboto:wght@300;400;500&display=swap');

::selection {
    background-color: #73303F;
    color: #FFF;
}

::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-thumb {
    background-color: #6C725A;
}
::-webkit-scrollbar-track {
    background-color: rgba(108, 114, 90, 0.4);
}

.yandex-map {
    width: 100%;
    height: 580px;
    margin-top: 4rem;
    border: 0;
}

.mantine-TextInput-input:focus, .mantine-TextInput-input {
    border: 1px solid #10222E;
    height: 48px;
}
.mantine-Textarea-input:focus, .mantine-Textarea-input {
    border: 1px solid #10222E;
    height: 6rem;
}
.sec-2-main, .sec-5-main { scroll-margin-top: 8rem;}
.sec-3-main { scroll-margin-top: 2rem;}
.sec-5-box { text-decoration: none; }
.footer-icons:hover { opacity: 0.4; }
.sec-2-image { float: right; border-right: 150px solid #10222E;}
@media screen and (max-width: 1220px) {
    .sec-2-block {
        width: 100%;
    }
    .sec-4-text, .sec-4-group {
        margin-top: 2rem;
    }
    .sec-4-scroll, .sec-4-image {
        width: 100%;
    }
    .sec-6-flex {
        width: 100%;
    }
    .sec-6-title, .sec-6-input, .sec-6-button, .sec-6-description, .sec-6-image {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    .button-cataloge {
        width: 100%;
    }
    .cataloge-component {
        width: 320px;
    }
}


@media screen and (max-width: 1020px) {

    .sec-1-group {
        padding-bottom: 2rem;
    }
    .video-block {
        width: 100%;
        height: 350px;
    }
    .sec-2-title {
        margin-top: 2rem;
    }
    .sec-2-image {
        width: 100%;
        height: 450px;
        border: 0;
    }
    .sec-2-group {
        margin-top: 2rem;
        float: none;
        width: 95vw;
    }
    .sec-2-box {
        height: auto;
        width: 100%;
        margin-bottom: 0.5rem;
    }
    .sec-2-text {
        margin-top: 2rem;
        text-align: center;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }
    .sec-2-group {
        padding-bottom: 2rem;
    }
    .footer-logo {
        margin-left: auto;
        margin-right: auto;
    }
    .footer-button {
        width: 100%;
    }
    .cataloge-component {
        width: 450px;
    }
}

@media screen and (max-width: 952px) {
    .cataloge-component {
        width: 45%;
    }
    .button-cataloge {
        height: 3rem;
        font-size: 18px;
    }
    .button-cataloge-group {
        margin-top: 2rem;
    }
    .Portfolio_flex {
        display: none;
    }
    .sec-seven-qr-flex {
        flex-direction: column;
        margin-top: 0;
        gap: 8;
    }
}

@media screen and (max-width: 768px) {
    .contact-container {
        max-width: 450px;
    }

    .cataloge-back-title {
        font-size: 24px;
    }
    .cataloge-back-group {
        margin-top: 8rem;
    }
    .cataloge-back-arrow {
        width: 3rem;
    }
    .cataloge-back-text {
        font-size: 24px;
        margin-bottom: 1.5rem;
        margin-left: 0.5rem;
    }
    .sec-1-title {
        font-size: 2.5rem;
        padding-top: 4rem;
    }
    .sec-1-box {
        margin-left: auto;
        margin-right: auto;
    }
    .sec-1-btn { margin-top: 4rem; height: 60px;}
    .sec-1-btn-text {
        display: none;
    }
    .cataloge-component {
        width: 400px;
    }
    .sec-6-title {
        width: 95%;
        font-size: 2rem;
    }
    .sec-6-input, .sec-6-button, .sec-6-description, .sec-6-image {
        width: 95%;
    }
    .sec-6-image {
        margin-top: 1rem;
    }
    .telegram-channel-btn {
        display: none;
    }
}

@media screen and (max-width: 628px) {
    .contact-container {
        max-width: 75%;
    }
    .contact-title {
        font-size: 24px;
    }

    .site-header {
        height: 4.5rem;
    }
    .sec-1-cataloge {
        padding-top: 4.5rem;
    }
    .header-logo {
        max-width: 180px;
    }
    .sec-2-block {
        height: 20rem;
    }
    .sec-3-text {
        font-size: 2rem;
    }
    .sec-3-box {
        height: 3rem;
        margin-top: 2rem;
        width: 80%;
    }
    .sec-3-grid {
        margin-top: 2rem;
        padding-bottom: 3rem;
    }
    .sec-4-title {
        font-size: 2rem;
    }
    .sec-4-group {
        padding-bottom: 2rem;
    }
    .sec-4-image {
        height: 25rem;
    }
    .sec-4-block {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
        margin-right: 1.5rem;
    }
    .sec-4-block-icon {
        width: 24px;
        height: 24px;
    }
    .sec-4-block-text {
        font-size: 14px;
        /* text-align: justify; */
        margin-top: 0;
        max-width: 250px;
    }
    .sec-5-image {
        height: 320px;
    }
    .sec-5-box {
        width: 340px;
    }
    .sec-5-title {
        font-size: 1rem;
    }
    .sec-5-description, .sec-5-material{
        font-size: 16px;
    }
    .sec-6-group {
        padding-bottom: 2rem;
    }
    .sec-6-image {
        height: 25rem;
    }
    .sec-6-description {
        font-size: 16px;
        text-align: justify;
    }
    .sec-7-text {
        font-size: 2rem;
    }
    .sec-7-box {
        height: 3rem;
        margin-top: 2rem;
        width: 80%;
    }
    .footer-group{
        padding-top: 2rem;
    }
    .footer-logo {
        width: 280px;
        height: 30px;
    }
    .footer-divider {
        margin-top: 2rem;
    }
    .cataloge-component {
        width: 100%;
    }
    .footer-icons {
        width: 32px;
    }
}

@media screen and (max-width: 425px) {
    .cataloge-back-title {
        font-size: 24px;
    }
    .cataloge-back-group {
        margin-top: 6rem;
    }
    .cataloge-back-arrow {
        width: 3rem;
    }
    .cataloge-back-text {
        font-size: 18px;
        margin-top: 0.3rem;
    }
    .contact-form-title {
        font-size: 12px;
    }
    .yandex-map {
        height: 450px;
    }
}


@media screen and (max-width: 375px) {
    .sec-1-title {
        font-size: 2rem;
        padding-top: 2rem;
    }
    .sec-3-text {
        font-size: 24px;
    }
    .sec-3-box, .sec-7-box {
        height: 2.5rem;
        width: 85%;
    }
    .sec-7-text {
        font-size: 24px;
    }
    .sec-6-title, .sec-6-input, .sec-6-button, .sec-6-description, .sec-6-image {
        width: 100%;
    }
    .footer-icons {
        width: 24px;
    }
}

.button-cataloge {
    border-radius: 0;
}

.button-cataloge:hover {
    background-color: #949E94;
    color: #FFF;
}

.mantine-Carousel-indicator {
    background-color: #ffffff;
    width: 16px;
    height: 16px;
    margin-right: 4px;
    margin-left: 4px;
}

.mantine-Carousel-control {
    background-color: rgba(1, 1, 1, 0);
    color: #FFF;
}

.sec-4-scroll::-webkit-scrollbar {
    width: 4px;
}
.sec-4-scroll::-webkit-scrollbar-thumb {
    background-color: #73303F;
}
.sec-4-scroll::-webkit-scrollbar-track {
    background-color: #949E94;
}