.avarus-main {
    height: max-content;
    background-size: cover;
    border-bottom: 8px solid #10222E;
    scroll-margin-top: 6rem;
}
.avarus-title {
    font-size: 3rem;
    font-family: Roboto Condensed;
    font-weight: 500;
    color: #FFF;
}
.avarus-country, .avarus-type {
    color: #FFF;
    font-family: Roboto Condensed;
    font-size: 2rem;
}
.avarus-description {
    color: #FFF;
    font-family: Roboto Condensed;
    font-weight: 300;
    max-width: 500px;
    margin-top: 1rem;
}
.avarus-button {
    width: 500px;
    height: 4rem;
    color: #10222E;
    background: #FFF;
    font-family: Roboto Condensed;
    margin-top: 2rem;
    text-decoration: none;
}
.avarus-button:hover {
    background: #FFF;
}
.avarus-image {
    width: 600px;
    height: 335px;
}
.stoleshnitsa-main {
    width: 500px;
    text-decoration: none;
}
.quartz-main {
    height: max-content;
    background: #10222E;
    margin-top: 4rem;
}
.quartz-title {
    font-size: 42px;
    color: #FFF;
    font-family: Roboto Condensed;
    font-weight: 700;
}
.quartz-description {
    font-size: 18px;
    color: #FFF;
    font-family: Roboto;
    font-weight: 400;
    width: 50%;
}
@keyframes stoleshnitsa {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.2);
    }
}
.stoleshnitsa-main:hover {
    animation: stoleshnitsa 350ms forwards;
}
.stoleshnitsa-image {
    display: flex;
    align-items: center;
    justify-content: center;
}
.stoleshnitsa-main-inside {
    width: 60%;
    margin-top: 4rem;
}
.stoleshnitsa-project-main {
    width: 35%;
    height: 370px;
    margin-top: 9rem;
    padding-left: 1rem;
}
.stoleshnitsa-project-title {
    font-size: 32px;
    font-family: Roboto;
    font-weight: 500;
    margin-bottom: 2rem;
    color: #10222E;
}
.stoleshnitsa-project-collection, .stoleshnitsa-project-collection-type, .stoleshnitsa-project-collection-size {
    font-size: 14px;
    font-family: Roboto;
    color: #949E94;
    font-weight: 500;
}
.stoleshnitsa-project-collection-title, .stoleshnitsa-project-collection-type-title {
    font-size: 24px;
    font-family: Roboto;
    color: #10222E;
    margin-bottom: 1rem;
}
.stoleshnitsa-project-collection-size-title {
    font-size: 24px;
    font-family: Roboto;
    color: #10222E;
}
@media screen and (max-width: 1180px) {
    .avarus-title {
        font-size: 24px;
    }
    .avarus-country, .avarus-type {
        font-size: 20px;
    }
    .avarus-button, .avarus-description {
        width: 350px;
    }
    .avarus-image {
        width: 500px;
        height: 350px;
    }
    .stoleshnitsa-main {
        width: 350px;
    }
    .stoleshnitsa-main-inside {
        width: 600px;
    }
    .stoleshnitsa-project-main {
        width: max-content;
        margin-top: 0;
        padding-top: 6rem;
    }
    .stoleshnitsa-image {
        height: 350px;
    }
    .stoleshnitsa-project-title {
        font-size: 24px;
    }
    .stoleshnitsa-project-collection-title,
    .stoleshnitsa-project-collection-type-title,
    .stoleshnitsa-project-collection-size-title {
        font-size: 18px;
    }
    .stoleshnitsa-project-collection,
    .stoleshnitsa-project-collection-type,
    .stoleshnitsa-project-collection-size {
        font-size: 12px;
    }
}
@media screen and (max-width: 900px) {
    .quartz-main {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .quartz-title {
        font-size: 32px;
    }
    .quartz-description {
        font-size: 16px;
        width: 100%;
    }
    .avarus-group {
        padding-top: 2rem;
        padding-bottom: 2rem;
        width: 100%;
    }
    .avarus-title, .avarus-type, .avarus-country, .avarus-description, .avarus-button {
        text-align: center;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .avarus-flex {
        width: 100%;
    }
    .avarus-image {
        height: 200px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}
@media screen and (max-width: 866px) {
    .stoleshnitsa-project-main {
        padding-top: 1rem;
        width: 600px;
        height: max-content;
        padding-left: 0;
    }
    .stoleshnitsa-project-title {
        margin-bottom: 1rem;
    }
}
@media screen and (max-width: 768px) {
    .stoleshnitsa-main {
        width: 90%;
    }
    .stoleshnitsa-image {
        height: 380px;
    }
}
@media screen and (max-width: 425px) {
    .stoleshnitsa-main {
        width: 320px;
    }
    .stoleshnitsa-image {
        height: 340px;
    }
    .stoleshnitsa-main-inside {
        width: 100%;
    }
}